
var Excelurl = 'https://script.google.com/macros/s/AKfycbzbdgpkF7xE6fv09_QoNsmXc78omUAvbfV6xEfxBGLPnloF02Eh/exec'
// document.getElementById('popup-overlay').style.display = 'block';
let overlayHome = false;
let overlay = false;
let overlayEducation = false;
let overlayArch = false;
let overlayReal = false;
let overlayHealthcare = false;
let overlayHospitality = false;
let overlayShowroom = false;
let overlayTourism = false;
overlay = localStorage.getItem('overlayStatus');
overlayHome = localStorage.getItem('overlayStatusHome');
overlayEducation = localStorage.getItem('overlayStatusEducation');
overlayArch = localStorage.getItem('overlayStatusArchitecture');
overlayReal = localStorage.getItem('overlayStatusRealestate');
overlayHealthcare = localStorage.getItem('overlayStatusHealthcare');
overlayHospitality = localStorage.getItem('overlayStatusHospitality');
overlayShowroom = localStorage.getItem('overlayStatusShowroom');
overlayTourism = localStorage.getItem('overlayStatusTourism');
function checkedURL() {
    let popupFormInterval = window.setInterval(function () {
        console.log("Status :: ", overlay)

        if (!overlayHome) {
            console.log("IF IN")
            document.getElementById('popup-overlay').style.display = 'flex';
            clearInterval(popupFormInterval);
        } else {
            console.log("IF OUT")
            document.getElementById('popup-overlay').style.display = 'none';
            clearInterval(popupFormInterval);
        }

        if (!overlayHome) {
            document.getElementById('popup-overlay').style.display = 'flex';
            clearInterval(popupFormInterval);
        } else {
            document.getElementById('popup-overlay').style.display = 'none';
            clearInterval(popupFormInterval);
        }

        if (!overlayEducation) {
            document.getElementById('popup-overlay').style.display = 'flex';
            clearInterval(popupFormInterval);
        } else {
            document.getElementById('popup-overlay').style.display = 'none';
            clearInterval(popupFormInterval);
        }

        if (!overlayArch) {
            document.getElementById('popup-overlay').style.display = 'flex';
            clearInterval(popupFormInterval);
        } else {
            document.getElementById('popup-overlay').style.display = 'none';
            clearInterval(popupFormInterval);
        }

        if (!overlayReal) {
            document.getElementById('popup-overlay').style.display = 'flex';
            clearInterval(popupFormInterval);
        } else {
            document.getElementById('popup-overlay').style.display = 'none';
            clearInterval(popupFormInterval);
        }

        if (!overlayHealthcare) {
            document.getElementById('popup-overlay').style.display = 'flex';
            clearInterval(popupFormInterval);
        } else {
            document.getElementById('popup-overlay').style.display = 'none';
            clearInterval(popupFormInterval);
        }

        if (!overlayHospitality) {
            document.getElementById('popup-overlay').style.display = 'flex';
            clearInterval(popupFormInterval);
        } else {
            document.getElementById('popup-overlay').style.display = 'none';
            clearInterval(popupFormInterval);
        }

        if (!overlayShowroom) {
            document.getElementById('popup-overlay').style.display = 'flex';
            clearInterval(popupFormInterval);
        } else {
            document.getElementById('popup-overlay').style.display = 'none';
            clearInterval(popupFormInterval);
        }

    }, 10000)
}
window.onload = checkedURL();
const btnOverlay = document.getElementById('button-submit-overlay');
btnOverlay.addEventListener('click', onSubmitOverlay);

function onSubmitOverlay(value) {
    // value.preventDefault();
    // console.log("Value :: ", e)
    console.log("YOu r Win");
    var userName = document.getElementById('user-name').value;
    var userNumber = document.getElementById('user-phone-no').value;
    var userEmail = document.getElementById('user-mail').value;
    var userState = document.getElementById('user-state').value;
    var userCountry = document.getElementById('select-country');
    var Selectedcountry = userCountry[userCountry.selectedIndex].text;
    var melzAbout = document.getElementById('melzoabout-overlay').value;
    let patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    //----LOADER CALL---
    document.getElementById('div-name').style.display = 'none';
    document.getElementById('btn-loader').style.display = 'block';
    //--------------------

    if (userName === "") {
        alert("Please enter Your Name !");
        //----LOADER CALL---
        document.getElementById('div-name').style.display = 'block';
        document.getElementById('btn-loader').style.display = 'none';
        //--------------------
    } else if (userNumber === "") {
        alert("Please enter 10 Digits(only) mobile number !");
        //----LOADER CALL---
        document.getElementById('div-name').style.display = 'block';
        document.getElementById('btn-loader').style.display = 'none';
        //--------------------
    } else if (userEmail === "") {
        alert("Please enter your valid Email-id !");
        //----LOADER CALL---
        document.getElementById('div-name').style.display = 'block';
        document.getElementById('btn-loader').style.display = 'none';
        //--------------------
    } else if (userState === "") {
        alert("Please enter Your State !");
        //----LOADER CALL---
        document.getElementById('div-name').style.display = 'block';
        document.getElementById('btn-loader').style.display = 'none';
        //--------------------
    } else if (Selectedcountry === '*Select Country') {
        alert("Please select country from dropdown");
        //----LOADER CALL---
        document.getElementById('div-name').style.display = 'block';
        document.getElementById('btn-loader').style.display = 'none';
        //--------------------
    } else {
        if (!patternEmail.test(userEmail)) {
            alert("Please enter a valid Email-id");
            //----LOADER CALL---
            document.getElementById('div-name').style.display = 'block';
            document.getElementById('btn-loader').style.display = 'none';
            //--------------------
        } else {

            document.getElementById('button-submit-overlay').disabled = true;
            var objEmail = {
                yourname: userName,
                telephone: userNumber,
                emailid: userEmail,
                state: userState,
                country: Selectedcountry,
                inquiry: melzAbout
            };

            axios.post(' https://us-central1-sendtoemail-3610d.cloudfunctions.net/overlay', objEmail, {
                headers: { // 'x-access-token': token
                }
            }).then(res => {
                //-------------Send Data on EXCEL----------------------

                var objExcel = {
                    Date: new Date().toLocaleString('en-IN', hour12 = true),
                    Name: userName,
                    Mobileno: userNumber,
                    Email: userEmail,
                    State: userState,
                    Country: Selectedcountry,
                    Inquiry: melzAbout
                };
                var jqxhr = $.ajax({
                    url: Excelurl,
                    method: "GET",
                    dataType: "json",
                    data: objExcel
                }).success(() => {
                    if (value === 'home') {
                        const statusFelx = true;
                        localStorage.setItem('overlayStatusHome', statusFelx);
                    } else if (value === 'industry') {
                        const statusFelx = true;
                        localStorage.setItem('overlayStatus', statusFelx);
                    } else if (value === 'educaion') {
                        const statusEducation = true;
                        localStorage.setItem('overlayStatusEducation', statusEducation);
                    } else if (value === 'architecture') {
                        const statusArchitecture = true;
                        localStorage.setItem('overlayStatusArchitecture', statusArchitecture);
                    } else if (value === 'real-estate') {
                        const statusReal = true;
                        localStorage.setItem('overlayStatusRealestate', statusReal);
                    } else if (value === 'healthcare') {
                        const statusHealthcare = true;
                        localStorage.setItem('overlayStatusHealthcare', statusHealthcare);
                    } else if (value === 'hospitality') {
                        const statusHospitality = true;
                        localStorage.setItem('overlayStatusHospitality', statusHospitality);
                    } else if (value === 'showroom') {
                        const statusShowroom = true;
                        localStorage.setItem('overlayStatusShowroom', statusShowroom);
                    } else if (value === 'tourism') {
                        const statusTourism = true;
                        localStorage.setItem('overlayStatusTourism', statusTourism);
                    }


                    //----LOADER CALL---
                    document.getElementById('div-name').style.display = 'block';
                    document.getElementById('btn-loader').style.display = 'none';
                    //--------------------

                    // document.getElementById("register-btn").removeAttribute("disabled");
                    document.getElementById('user-name').value = "";
                    document.getElementById('user-phone-no').value = "";
                    document.getElementById('user-mail').value = "";
                    document.getElementById('user-state').value = "";
                    document.getElementById("button_Melzofy").disabled = false;
                    // location.href = "../thanks.html";
                    document.getElementById('popup-overlay').style.display = 'none';
                    document.getElementById('button-submit-overlay').style.display = 'none';

                    location.href = "../thanks.html";
                });
                //--------------------------------------------------
            })
        }
    }
}

const closebtn = document.getElementById('overlay-popup-close');
closebtn.addEventListener('click', onSubmitClose);

function onSubmitClose(value) {
    // e.preventDefault();
    if (value === 'home') {
        const statusFelx = true;
        localStorage.setItem('overlayStatusHome', statusFelx);
    } else if (value === 'industry') {
        const statusFelx = true;
        localStorage.setItem('overlayStatus', statusFelx);
    } else if (value === 'educaion') {
        const statusEducation = true;
        localStorage.setItem('overlayStatusEducation', statusEducation);
    } else if (value === 'architecture') {
        const statusArchitecture = true;
        localStorage.setItem('overlayStatusArchitecture', statusArchitecture);
    } else if (value === 'real-estate') {
        const statusreal = true;
        localStorage.setItem('overlayStatusRealestate', statusreal);
    } else if (value === 'healthcare') {
        const statusHealthcare = true;
        localStorage.setItem('overlayStatusHealthcare', statusHealthcare);
    } else if (value === 'hospitality') {
        const statusHospitality = true;
        localStorage.setItem('overlayStatusHospitality', statusHospitality);
    } else if (value === 'showroom') {
        const statusShowroom = true;
        localStorage.setItem('overlayStatusShowroom', statusShowroom);
    } else if (value === 'tourism') {
        const statusTourism = true;
        localStorage.setItem('overlayStatusTourism', statusTourism);
    }
    document.getElementById('popup-overlay').style.display = 'none';

}